export function useLobbyGameApi() {
  const API_VER = useRuntimeConfig().API_VER_1
  const createEndpoint = (endpoint: string) => `${API_VER}/${endpoint}`
  return {
    JACKPOT: createEndpoint('slot/jackpot'),
    SUM_JACKPOT: createEndpoint('slot/sumjackpot'),
    LOBBY_CATEGORY: createEndpoint('brand/category'),
    SPORT_CATEGORY: createEndpoint('brand/category?alias=the-thao'),
    LIST_LOBBY_GAME: createEndpoint('brand/games'),
    LIST_LOBBY_CASINO: createEndpoint('brand/livecasino'),
    LIVE_CASINO_URL: createEndpoint('casinoUrl'),
    GAME_URL: createEndpoint('gameUrl'),
    HOME_BANNER: createEndpoint('brand/banner')
  }
}
